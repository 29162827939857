
import { PopoutsModule } from "@/store/modules/popouts";
import { PropType } from "vue";
import ButtonTemplate from "@/components/CustomButton.vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfilePopout",
  components: { ButtonTemplate },
  props: {
    data: {
      type: Object as PropType<{
        url: string;
        unsafe_url: string;
      }>,
      required: true,
    },
  },
  methods: {
    showContext(event: any) {
      if (!this.$isElectron) return;
      event.preventDefault();
      PopoutsModule.ShowPopout({
        id: "context",
        component: "ImageContextMenu",
        key: this.data.url + event.clientX + event.clientY,
        data: {
          x: event.clientX,
          y: event.clientY,
          imageUrl: this.data.url,
        },
      });
    },
    async downloadImage() {
      const a = document.createElement("a");
      a.href = await this.toDataURL(this.data.url);
      a.download = this.basename(this.data.unsafe_url || this.data.url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async toDataURL(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    },
    basename(path: string) {
      return path.substring(path.lastIndexOf("/") + 1);
    },
    backgroundClick(event: any) {
      if (event.target.closest(".options")) return;
      PopoutsModule.ClosePopout("image-preview-popout");
    },
  },
});
